import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import Error from './components/error/Error'
import Debug from './components/debug/Debug';
import ScreenerQuestion from './components/screener/ScreenerQuestion';
import ScreenerInit from "./components/screener/ScreenerInit";
import ThankYou from './components/screener/ThankYou';
import Login from './components/home/Login';
import {useSelector} from 'react-redux';
import {StateParams} from './store/reducers';
import ReasonForVisit from './components/home/ReasonForVisit';
import Welcome from './components/screener/WelcomeScreen';
import SuicidalHelpline from './components/screener/SuicidalHelpline';
import PracticeEntry from "./components/home/PracticeEntry";
import ForgotPassword from './components/home/ForgotPassword';
import ResetPassword from './components/home/ResetPassword';
import {LoadingComponent} from './components/loading/LoadingComponent';
import {RequireAuth} from './components/auth/AuthContext';
import BeforeAdhdNote from './components/notes/BeforeAdhdNote';
import TwelveToEighteenNote from './components/notes/TwelveToEighteenNote';
import PatientsScreen from './components/provider/PatientsScreen';
import TermsAndConditions from './components/screener/TermsAndConitions';
import flagsmith from "flagsmith";
import {FlagsmithProvider} from 'flagsmith/react';
import {ProviderLogin} from "./components/home/ProviderLogin";
import {LanguagePreferenceComponent} from "./components/loading/LanguagePreferenceComponent";
import PreferredLanguage from './components/home/PreferredLanguageSelection';
import {
    RenderDob,
    RenderFirstName,
    RenderGender,
    RenderLastName,
    RenderPassword,
    RenderWhoIsFilling,
    RenderZipcode
} from './components/home/SignupNew';
import {
    RenderGuardianEmail,
    RenderGuardianFirstName,
    RenderGuardianLastName
} from './components/home/LegalGuardianFormNew';
import BelowTwelveNote from './components/notes/BelowTwelveNote';
import {
    RenderEmergencyCanShare,
    RenderEmergencyEmail,
    RenderEmergencyFirstName,
    RenderEmergencyLastName,
    RenderEmergencyPhoneNumber,
    RenderEmergencyRelationType
} from './components/home/ContactFormNew';
import AdminPreferredLanguage from './components/home/AdminPreferredLanguageSelection';
import AdminPatientSignupLanguageSelection from './components/home/AdminPatientSignupLanguageSelection';
import AdminFlowPhoneNumberEntry from './components/home/AdminFlowPhoneNumberEntry';
import {MixpanelProvider} from "./analytics/Mixpanel";
import PatientScreen from './components/provider/PatientScreen';
import PatientScreeningEntryScreen from "./components/patient-screening-entry/PatientScreeningEntryScreen";
import PatientProfileScreen from "./components/patient-screening-entry/PatientProfileScreen";

const FLAG_SMITH_ENV: { [key: string]: string } = {
    "screener.dev.smalljoys.life": "fMSowWsg7omQZkxABAJo5o",
    "screener.uat.smalljoys.life": "8jxsXa5N43VihEtxiKgXpX",
    "localhost": "axe6UkgSDXs4QktJuwru2K",
    "screener.prod.smalljoys.life": "jWPHMdZRSRy88FbFzje7P7",
}

const host = window.location.host
const isLocalHost = host.startsWith("localhost")
const FLAG_SMITH_ENV_ID = FLAG_SMITH_ENV[isLocalHost ? "localhost" : host] || "jWPHMdZRSRy88FbFzje7P7"

const App = () => {
    const {rehydrate} = useSelector((state: StateParams) => state.loading)

    function getRouter() {
        return <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/adminLogin" replace/>}/>
                <Route path="/practice" element={<LoadingComponent><PracticeEntry/></LoadingComponent>}/>
                <Route path="/practice/:slug" element={<LoadingComponent><PreferredLanguage/></LoadingComponent>}/>
                <Route path="/languagePreference" element={<LoadingComponent><PreferredLanguage/></LoadingComponent>}/>
                <Route path="/adminLanguagePreference"
                       element={<LoadingComponent><AdminPreferredLanguage/></LoadingComponent>}/>
                <Route path="/adminPatientSignupLanguagePreference"
                       element={<LoadingComponent><AdminPatientSignupLanguageSelection/></LoadingComponent>}/>
                <Route path="/adminFlowPhoneNumberEntry"
                       element={<LoadingComponent><AdminFlowPhoneNumberEntry/></LoadingComponent>}/>
                <Route path="/signup">
                    <Route path="firstName" element={<LoadingComponent><RenderFirstName/></LoadingComponent>}/>
                    <Route path="lastName" element={<LoadingComponent><RenderLastName/></LoadingComponent>}/>
                    <Route path="dob" element={<LoadingComponent><RenderDob/></LoadingComponent>}/>
                    <Route path="gender" element={<LoadingComponent><RenderGender/></LoadingComponent>}/>
                    <Route path="zipcode" element={<LoadingComponent><RenderZipcode/></LoadingComponent>}/>
                    <Route path="password" element={<LoadingComponent><RenderPassword/></LoadingComponent>}/>
                    <Route path="whoIsFilling" element={<LoadingComponent><RenderWhoIsFilling/></LoadingComponent>}/>
                </Route>
                <Route path="/login" element={<LoadingComponent><Login/></LoadingComponent>}/>
                <Route path="/adminLogin" element={<LoadingComponent><ProviderLogin/></LoadingComponent>}/>
                <Route path="/legalGuardian">
                    <Route path="firstName" element={<LoadingComponent><RenderGuardianFirstName/></LoadingComponent>}/>
                    <Route path="lastName" element={<LoadingComponent><RenderGuardianLastName/></LoadingComponent>}/>
                    <Route path="email" element={<LoadingComponent><RenderGuardianEmail/></LoadingComponent>}/>
                </Route>
                <Route path="/belowTwelveNote" element={<LoadingComponent><BelowTwelveNote/></LoadingComponent>}/>
                <Route path="/reasonForVisit" element={<LoadingComponent><ReasonForVisit/></LoadingComponent>}/>
                <Route path="/welcome" element={<RequireAuth><Welcome/></RequireAuth>}/>
                <Route path="/termsAndCondition" element={<RequireAuth><TermsAndConditions/></RequireAuth>}/>
                <Route path="/debug" element={<Debug/>}/>
                <Route path="/screenerQuestion"
                       element={<LoadingComponent><RequireAuth><ScreenerInit/></RequireAuth></LoadingComponent>}/>
                <Route path="/screener/:userScreenerId/question/:questionId"
                       element={<LoadingComponent><RequireAuth><ScreenerQuestion/></RequireAuth></LoadingComponent>}/>
                <Route path="/thankYou" element={<RequireAuth><ThankYou/></RequireAuth>}/>
                <Route path="/suicidalHelpline" element={<RequireAuth><SuicidalHelpline/></RequireAuth>}/>
                <Route path="/forgotPassword" element={<ForgotPassword/>}/>
                <Route path="/resetPassword" element={<ResetPassword/>}/>
                <Route path="/emergencyContact">
                    <Route path="firstName" element={<LoadingComponent><RenderEmergencyFirstName/></LoadingComponent>}/>
                    <Route path="lastName" element={<LoadingComponent><RenderEmergencyLastName/></LoadingComponent>}/>
                    <Route path="email" element={<LoadingComponent><RenderEmergencyEmail/></LoadingComponent>}/>
                    <Route path="phoneNumber"
                           element={<LoadingComponent><RenderEmergencyPhoneNumber/></LoadingComponent>}/>
                    <Route path="relationType"
                           element={<LoadingComponent><RenderEmergencyRelationType/></LoadingComponent>}/>
                    <Route path="canShare" element={<LoadingComponent><RenderEmergencyCanShare/></LoadingComponent>}/>
                </Route>
                <Route path="/twelveToEighteenNote" element={<TwelveToEighteenNote/>}/>
                <Route path="/adhdNote" element={<BeforeAdhdNote/>}/>
                <Route path="/patients"
                       element={<LoadingComponent><RequireAuth><PatientsScreen/></RequireAuth></LoadingComponent>}/>
                <Route path="/patients/:id"
                       element={<LoadingComponent><RequireAuth><PatientScreen/></RequireAuth></LoadingComponent>}/>
                <Route path="/patientProfile" element={
                    <LoadingComponent><RequireAuth><PatientProfileScreen/></RequireAuth></LoadingComponent>}/>
                <Route path="/patientScreeningEntry/:patientId/practice/:practiceId"
                       element={<LoadingComponent><PatientScreeningEntryScreen/></LoadingComponent>}/>
                <Route path="*" element={<Error/>}/>
            </Routes>
        </Router>;
    }

    return (
        rehydrate ?
            <FlagsmithProvider
                options={{
                    environmentID: FLAG_SMITH_ENV_ID,
                }}
                flagsmith={flagsmith}>
                <MixpanelProvider>
                    <div className='w-full h-full bg-main-lg bg-cover bg-no-repeat shadow-lg mb-2 flex-col'>
                        <div className='h-full mx-auto sm:max-w-xl border-solid border-2'>
                            {getRouter()}
                        </div>
                    </div>
                </MixpanelProvider>
                <LanguagePreferenceComponent/>
            </FlagsmithProvider> :
            <></>
    );
}

export default App;
