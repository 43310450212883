import { call, put, takeLatest } from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {apiHttpClient} from "../../lib";
import {
    IScreenerCurrentData,
    IScreenerAnswerResponse,
    IScreenerQuestionDetails,
    IPatient,
    IPatientResponse,
    LanguageCodes,
    UserScreenerMultiSelect,
    IPatientDetailsResponse
} from "../../models";
import {
    FETCH_CURRENT_QUESTION,
    fetchedCurrentQuestion,
    failedFetchingCurrentQuestion,
    FETCH_QUESTION_DETAILS,
    fetchedQuestionDetails,
    failedFetchingQuestionDetails,
    SAVE_USER_SCREENER_ANSWER,
    savedUserScreenerAnswer,
    failedSavingUserScreenerAnswer,
    FETCH_PATIENTS,
    fetchingPatientsFailed,
    fetchedPatients,
    savedLanguagePreference,
    savingLanguagePreferenceFailed,
    SAVE_LANGUAGE_PREFERENCE,
    FETCH_PATIENT, fetchedPatient, fetchingPatientFailed
} from '../actions/screenerQuestions.action';
import { callFinished, callInProgress } from "../actions/loading.action";

const apiFetchCurrentQuestion = (request: {screenerId: string, token: string, practiceId: string, adminToken: string, userId: string}) => {
    const config = { headers: { "x-auth-token": request.token, "x-admin-token": request.adminToken, "x-practice-id": request.practiceId, "x-user-id": request.userId }}
    return apiHttpClient.get<IScreenerCurrentData>(`/${request.screenerId}/question`, config)
        .then((response: AxiosResponse) => {
            return response.data.data.data as IScreenerCurrentData
        }).catch((e) => {
            console.log(e)
            throw "FailedFetchingCurrentQuestion"
        });
}

const apiFetchQuestionDetails = (request: {screenerId: string, questionId: string, preferredLanguage?: LanguageCodes, token: string, practiceId: string, adminToken: string, userId: string} ) => {
    const config = { headers: { "x-auth-token": request.token, "x-admin-token": request.adminToken, "x-practice-id": request.practiceId, "x-user-id": request.userId }, params: {preferredLanguage: request.preferredLanguage}}
    return apiHttpClient.get<IScreenerQuestionDetails>(`/${request.screenerId}/question/${request.questionId}`, config)
        .then((response: AxiosResponse) => {
            return response.data.data.data as IScreenerQuestionDetails
        }).catch((e) => {
            console.log(e)
            throw "FailedFetchingQuestionDetails"
        });
}

const apiSaveUserAnswer = (request: {loginSource: string | undefined, screenerId: string, questionId: string, answerId: string, response?: UserScreenerMultiSelect[], token: string, practiceId: string, adminToken: string, userId: string}) => {
    const responseListPayload = request.response
    const config = { headers: { "x-auth-token": request.token, "x-admin-token": request.adminToken, "x-practice-id": request.practiceId, "x-user-id": request.userId } }
    return apiHttpClient.post<IScreenerAnswerResponse>(`/${request.screenerId}/question/${request.questionId}/answer/${request.answerId}`,
        { responses: responseListPayload, loginSource: (request.loginSource || null) }, config)
        .then((response: AxiosResponse) => {
            return response.data.data.data as IScreenerAnswerResponse
        }).catch((e) => {
            console.log(e)
            throw "FailedFetchingQuestionDetails"
        });
}

const apiFetchPatients = (request: { basePath: string, pageNumber: number, recordsPerPage: number, searchText: string, practiceId: string, adminToken: string}) => {
    const config = {headers: {"x-admin-token": request.adminToken, "x-practice-id": request.practiceId}}
    return apiHttpClient.get<IPatient[]>(`${request.basePath}?pageNumber=${request.pageNumber}&recordsPerPage=${request.recordsPerPage}&searchText=${request.searchText}`, config)
        .then((response: AxiosResponse) => {
            return response.data as IPatientResponse
        }).catch((e) => {
            console.log(e)
            throw "FailedFetchingQuestionDetails"
        });
}

const apiFetchPatient = (request: { patientId: string, practiceId: string, adminToken: string}) => {
    const config = {headers: {"x-admin-token": request.adminToken, "x-practice-id": request.practiceId}}
    return apiHttpClient.get<IPatientDetailsResponse>(`/admin/patients/${request.patientId}`, config)
        .then((response: AxiosResponse) => {
            return response.data as IPatientDetailsResponse
        }).catch((e) => {
            console.log(e)
            throw "FailedFetchingPatientDetails"
        });
}
const apiPreferredLanguage = (request: { token: string, adminToken: string, practiceId: string, userId: string, preferredLanguage: LanguageCodes }) => {
    const config = { headers: {"x-auth-token": request.token, "x-user-id": request.userId, "x-admin-token": request.adminToken, "x-practice-id": request.practiceId }}
    return apiHttpClient.put<{preferredLanguage: LanguageCodes}>(`/language-preference`, {preferredLanguage: request.preferredLanguage},config)
    .then((response: AxiosResponse) => {
        return response.data.data as {preferredLanguage: LanguageCodes}
    }).catch((e) => {
        throw "Failed accepts terms and condition"
    });
}

function* fetchCurrentQuestion(action: {type: string, account: {token: string, adminToken: string, practiceId: string, userId: string}, payload: {screenerId: string}}) {
    try{
        console.log(`ScreenerSaga:fetchCurrentQuestion`)
        yield put(callInProgress())
        const currentQuestion: IScreenerCurrentData = yield call(apiFetchCurrentQuestion, {...action.payload, token: action.account.token, practiceId: action.account.practiceId, adminToken: action.account.adminToken, userId: action.account.userId})
        console.log(`ScreenerSaga:fetchedCurrentQuestion`)
        yield put(fetchedCurrentQuestion({tobeAnsweredQuestion: currentQuestion.tobeAnsweredQuestion, isScreenerCompleted: currentQuestion.isScreenerCompleted, allScreenerQuestions: currentQuestion.allScreenerQuestions}));
    } catch (e: any){
        console.log(e)
        yield put(failedFetchingCurrentQuestion({error: e.toString()}))
    } finally {
        yield put(callFinished())
    }
}

function* fetchQuestionDetails(action: {type: string, account: {token: string, adminToken: string, practiceId: string, userId: string}, payload: {screenerId: string, questionId: string, preferredLanguage?: LanguageCodes}}) {
    try{
        console.log(`ScreenerSaga:fetchQuestionDetails`)
        yield put(callInProgress())
        const questionDetails: IScreenerQuestionDetails = yield call(apiFetchQuestionDetails, {...action.payload, token: action.account.token, practiceId: action.account.practiceId, adminToken: action.account.adminToken, userId: action.account.userId})
        console.log(`ScreenerSaga:fetchedQuestionDetails`)
        yield put(fetchedQuestionDetails({currentQuestionDetails: questionDetails.currentQuestion, allScreenerQuestions: questionDetails.allScreenerQuestions}));
    } catch (e: any){
        console.log(e)
        yield put(failedFetchingQuestionDetails({error: e.toString()}))
    } finally {
        yield put(callFinished())
    }
}

function* saveUserAnswer(action: {type: string, account: {token: string, adminToken: string, practiceId: string, userId: string}, payload: {loginSource: string | undefined, screenerId: string, questionId: string, answerId: string, response?: UserScreenerMultiSelect[]}}) {
    try{
        console.log(`ScreenerSaga:saveUserAnswer`)
        yield put(callInProgress())
        const nextQuestion: IScreenerAnswerResponse = yield call(apiSaveUserAnswer, {...action.payload, token: action.account.token, practiceId: action.account.practiceId, adminToken: action.account.adminToken, userId: action.account.userId})
        console.log(`ScreenerSaga:savedUserAnswer`)
        yield put(savedUserScreenerAnswer({nextQuestionLink: nextQuestion.nextQuestionLink, nextScreenerId: nextQuestion.nextScreenerId, nextScreenerSlug: nextQuestion.nextScreenerSlug, isScreenerPositive: nextQuestion.isScreenerPositive }));
    } catch (e: any){
        console.log(e)
        yield put(failedSavingUserScreenerAnswer({error: e.toString()}))
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatients(action: { type: string, account:{practiceId: string, adminToken: string}, payload: {basePath: string, pageNumber: number, recordsPerPage: number, searchText: string}}) {
    try {
        console.log(`PatientSaga:fetchPatients`)
        yield put(callInProgress())
        const apiFetchPatientsResponse: IPatientResponse = yield call(apiFetchPatients, {...action.payload, practiceId: action.account.practiceId, adminToken: action.account.adminToken})
        console.log(`PatientSaga:fetchPatients->\n fetched all patients with count: ${apiFetchPatientsResponse.recordsCount}`)
        if(apiFetchPatientsResponse) {
            yield put(fetchedPatients({patients: apiFetchPatientsResponse.data, recordsCount: apiFetchPatientsResponse.recordsCount}))
        } else {
            yield put(fetchingPatientsFailed({error: "Unknown error occurred while fetching patients"}));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientsFailed({error: "Error fetching patients!"}));
    } finally {
        yield put(callFinished())
    }
}

function* fetchPatient(action: { type: string, account:{practiceId: string, adminToken: string}, payload: {patientId: string}}) {
    try {
        console.log(`PatientSaga:fetchPatient`)
        yield put(callInProgress())
        const apiFetchPatientsResponse: IPatientDetailsResponse = yield call(apiFetchPatient, {
            ...action.payload,
            practiceId: action.account.practiceId,
            adminToken: action.account.adminToken
        })
        if (apiFetchPatientsResponse) {
            yield put(fetchedPatient({patient: apiFetchPatientsResponse.data.patient}))
        } else {
            yield put(fetchingPatientFailed({error: "Unknown error occurred while fetching patient"}));
        }
    } catch (e) {
        console.log(e)
        yield put(fetchingPatientFailed({error: "Error fetching patient!"}));
    } finally {
        yield put(callFinished())
    }
}
function* savePreferredLanguage(action: { type: string, account: {token: string, adminToken: string, practiceId: string, userId: string }, payload: {preferredLanguage: LanguageCodes}}) {
    try {
        console.log(`PatientSaga: savePreferredLanguage`)
        yield put(callInProgress())
        const preferredLanguageResponse: {preferredLanguage: LanguageCodes} = yield call(apiPreferredLanguage, { ...action.payload, practiceId: action.account.practiceId, adminToken: action.account.adminToken, userId: action.account.userId, token: action.account.token })
        console.log(`PatientSaga:savePreferredLanguage `)
        if(preferredLanguageResponse) {
            yield put(savedLanguagePreference({preferredLanguage: preferredLanguageResponse.preferredLanguage}))
        } else {
            yield put(savingLanguagePreferenceFailed({error: "Unknown error occurred while saving language"}));
        }
    } catch (e) {
        console.log(e)
        yield put(savingLanguagePreferenceFailed({error: "Error saving preferred language!"}));
    } finally {
        yield put(callFinished())
    }
}

export default function* screenerQuestionsSaga() {
    yield takeLatest(FETCH_CURRENT_QUESTION, fetchCurrentQuestion)
    yield takeLatest(FETCH_QUESTION_DETAILS, fetchQuestionDetails)
    yield takeLatest(SAVE_USER_SCREENER_ANSWER, saveUserAnswer)
    yield takeLatest(FETCH_PATIENTS, fetchPatients)
    yield takeLatest(FETCH_PATIENT, fetchPatient)
    yield takeLatest(SAVE_LANGUAGE_PREFERENCE, savePreferredLanguage)
}
