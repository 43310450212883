import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { clearUserId, clearUserScreenerId, patientScreenerStart, patientScreenerVisits } from "../../store/actions/account.action";
import { StateParams } from "../../store/reducers";
import {MixpanelContext} from "../../analytics/Mixpanel";
import { VisitTypeCodes } from "../../models";
import Modal from 'react-modal';

interface Value {
    hasPendingOrElapsedScreeners: boolean
    isEligibleForScreeningBasedOnStatus: boolean
    userId: string
    dob: Date
}

export const PatientScreenerStatusPill: React.FC<{ value: Value }> = ({ value }) => {
    const analytics = React.useContext(MixpanelContext);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userScreenerId, provider, visits, loginSource } = useSelector((state: StateParams) => state.account)
    const [patientDob, setPatientDob] = useState<Date>(value.dob)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [isVisitSelected, setIsVisitSelected] = useState<VisitTypeCodes>();

    const goToDetails = useCallback(() => {
        dispatch(patientScreenerVisits({
            userId: value.userId
        }))
    }, [value])

    const handleScreenerContine = useCallback(() => {
        setIsOpen(isOpen => !isOpen)
        analytics?.identify(value.userId)
        analytics?.track("start_admin_screening", { provider: provider?.slug, patientId: value.userId })
        dispatch(patientScreenerStart({
            userId: value.userId, practiceType: provider?.practiceType, visitType: isVisitSelected as VisitTypeCodes,
            isInsuredByMedicare: true, loginSource
        }))
    }, [isVisitSelected])

    useEffect(() => {
        if (userScreenerId && patientDob) {
            navigate('/adminLanguagePreference', {state: {dob: patientDob}})
        }
    }, [userScreenerId])

    useEffect(() => {
        if(visits) {
            setIsOpen(true)
            dispatch(clearUserScreenerId())
        }
    }, [visits])

    const handleChange = (value: VisitTypeCodes) => {
        dispatch(clearUserScreenerId())
        setIsVisitSelected(value)
    }

    const renderVisitsModal = () => {
        const startScreening = isVisitSelected
        return (
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(isOpen => !isOpen)}
                contentLabel="Example Modal"
                style={{ content: { width: '25%', height: '25%', margin: 'auto', backgroundColor: 'white' } }}
            >
                <h2>Visits</h2>
                <div>
                        <select
                        onChange={(e) => handleChange(e.target.value as VisitTypeCodes)}
                        >
                        <option value="-1" defaultChecked>Select</option>
                        {visits?.map(visit => {
                            return <option //disabled={!visit.areScreenersAvailable}
                             value={visit.visitType}>{visit.visitType} </option>
                        })}
                        </select>
                    </div>
                <button className="items-center px-2 py-2 border text-sm font-medium rounded-md bg-orange first-letter:rounded-l-md border-white text-white"
                    onClick={handleScreenerContine} disabled={!startScreening}>
                    {isVisitSelected && `Continue`}
                    {!isVisitSelected && `Select a visit`}
                </button>
            </Modal>
        )
    }

    return (
        <>
            {renderVisitsModal()}
            {value.isEligibleForScreeningBasedOnStatus ?
            <button className="items-center px-2 py-2 border text-sm font-medium rounded-md bg-orange first-letter:rounded-l-md border-white text-white"
                onClick={goToDetails} >
                Start Screening
            </button>
            : null}
        </>
    );
}
