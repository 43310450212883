import {
    ACCOUNT_CREATED,
    ACCOUNT_CREATION_FAILED,
    ACCOUNT_SIGNED_IN,
    ACCOUNT_SIGNIN_FAILED,
    ACCOUNT_SIGN_OUT,
    SET_NEXT_USER_SCREENER_ID,
    SET_SCREENER_SLUG,
    SET_PRACTICE_ID,
    SEND_VERIFICATION_CODE_RESPONSE,
    VERIFY_CODE_RESPONSE,
    SEND_VERIFICATION_CODE_FAILED,
    VERIFY_CODE_FAILED,
    CLEAR_ERROR_MESSAGES,
    RESET_PASSWORD_RESPONSE,
    RESET_PASSWORD_FAILED,
    GET_PRACTICE_SUCCESS,
    GET_PRACTICE_FAILED,
    PROVIDER_LOGIN_SUCCESS,
    PROVIDER_LOGIN_FAILED,
    SET_USER_ID,
    CLEAR_USER_ID,
    PATIENT_SCREENER_STARTED,
    PATIENT_SCREENER_STARTING_FAILED,
    ACCEPT_TERMS,
    ACCEPT_TERMS_FAILED,
    ACCEPT_TERMS_SUCCESS,
    SET_LANGUAGE_PREFERENCE,
    SET_PRACTICE_SLUG,
    PROVIDER_SIGN_OUT,
    SET_SCREEN_NAME, PATIENT_TEMP_LOGGED_IN, PATIENT_TEMP_LOG_IN_FAILED, PATIENT_TEMP_LOGIN,
    PATIENT_SCREENER_VISITS,
    PATIENT_SCREENER_VISITS_FAILED,
    PATIENT_SCREENER_VISITS_SUCCESS,
    CLEAR_USER_SCREENER_ID
} from "../actions/account.action";
import {IAccount, IProviderUser, LanguageCodes, PatientScreeningVisit} from "../../models";

export interface AccountParams {
    jwt?: string
    id?: string
    slug?: string
    practiceId?: string
    phoneNumber?: string
    nickName?: string
    userScreenerId?: string
    userScreenerSlug?: string
    createAccountError?: string
    signInError?: string
    isSentSuccessfully?: boolean,
    isVerified?: boolean,
    sendCodeError?: string
    codeVerifyError?: string
    practiceIdError?: string
    provider?: IAccount;
    providerSignInError?: string
    patientScreenerError?: string
    isTermsAccepted?: boolean
    isTermsAcceptedError?: boolean
    hasAcceptedTerms?: boolean
    languagePreference?: LanguageCodes
    screenName?: string,
    visits?: PatientScreeningVisit[]
    loginSource?: string
}

const initialState: AccountParams = {
    jwt: undefined,
    id: undefined,
    slug: undefined,
    phoneNumber: undefined,
    nickName: undefined,
    userScreenerId: undefined,
    createAccountError: undefined,
    signInError: undefined,
    userScreenerSlug: undefined,
    isSentSuccessfully: undefined,
    isVerified: undefined,
    sendCodeError: undefined,
    codeVerifyError: undefined,
    practiceId: undefined,
    practiceIdError: undefined,
    provider: undefined,
    providerSignInError: undefined,
    patientScreenerError: undefined,
    isTermsAccepted: undefined,
    isTermsAcceptedError: undefined,
    hasAcceptedTerms: undefined,
    languagePreference: undefined,
    screenName: undefined,
    visits: undefined,
    loginSource: undefined
}

const AccountReducer = (state: AccountParams = initialState, action: any): AccountParams => {
    switch (action.type) {
        case ACCOUNT_CREATED:
            state = { ...state, jwt: action.payload.jwt, id: action.payload.id, phoneNumber: action.payload.phoneNumber, nickName: action.payload.nickname, userScreenerId: action.payload.userScreenerId, userScreenerSlug: action.payload.userScreenerSlug, languagePreference: action.payload.preferredLanguage, createAccountError: undefined }
            return state
        case ACCOUNT_CREATION_FAILED:
            state = { ...state, jwt: undefined, createAccountError: action.payload.error }
            return state
        case ACCOUNT_SIGNED_IN:
            state = { ...state, jwt: action.payload.jwt, nickName: action.payload.nickname, id: action.payload.id,
                phoneNumber: action.payload.phoneNumber,
                loginSource: "patient_web",
                languagePreference: action.payload.preferredLanguage,
                userScreenerId: action.payload.userScreenerId, userScreenerSlug: action.payload.userScreenerSlug,
                signInError: undefined, hasAcceptedTerms: action.payload.hasAcceptedTerms}
            return state
        case PATIENT_TEMP_LOGGED_IN:
            state = { ...state, jwt: action.payload.jwt, nickName: action.payload.nickname, id: action.payload.id,
                phoneNumber: action.payload.phoneNumber,
                loginSource: action.payload.loginSource,
                languagePreference: action.payload.preferredLanguage,
                userScreenerId: action.payload.userScreenerId, userScreenerSlug: action.payload.userScreenerSlug,
                signInError: undefined, hasAcceptedTerms: action.payload.hasAcceptedTerms}
            return state
        case ACCOUNT_SIGNIN_FAILED:
        case PATIENT_TEMP_LOG_IN_FAILED:
            state = { ...state, jwt: undefined, signInError: action.payload.error }
            return state
        case ACCOUNT_SIGN_OUT:
            state = { ...state, ...initialState}
            return state

        case PROVIDER_SIGN_OUT:
            state = { ...state, provider: undefined, screenName: undefined}
            return state

        case SET_NEXT_USER_SCREENER_ID:
            state = { ...state, userScreenerId: action.payload.nextUserScreenerId }
            return state
        case SET_SCREENER_SLUG:
            state = { ...state, userScreenerSlug: action.payload.nextScreenerSlug }
            return state
        case SET_PRACTICE_ID:
            state = { ...state, practiceId: action.payload.practiceId }
            return state
        case GET_PRACTICE_SUCCESS:
            state = { ...state, practiceId: action.payload.practiceId }
            return state
        case GET_PRACTICE_FAILED:
            state = { ...state, practiceIdError: action.payload.error }
            return state
        case SEND_VERIFICATION_CODE_RESPONSE:
            state = { ...state, isSentSuccessfully : action.payload.isSentSuccessfully, phoneNumber: action.payload.phoneNumber, sendCodeError: undefined }
            return state
        case SEND_VERIFICATION_CODE_FAILED:
            state = { ...state,  sendCodeError: action.payload.error, isSentSuccessfully: action.payload.isSentSuccessfully}
            return state
        case VERIFY_CODE_RESPONSE:
            state = { ...state, isVerified : action.payload.isVerified, phoneNumber: action.payload.phoneNumber, codeVerifyError: undefined }
            return state
        case VERIFY_CODE_FAILED:
            state = { ...state,  codeVerifyError: action.payload.error, isVerified: action.payload.isVerified}
            return state
        case CLEAR_ERROR_MESSAGES:
            state = {...state, codeVerifyError: undefined, isVerified: undefined, isSentSuccessfully: undefined, signInError: undefined, createAccountError: undefined, providerSignInError: undefined }
            return state
        case RESET_PASSWORD_RESPONSE:
            state = { ...state, jwt: action.payload.jwt, nickName: action.payload.nickname, id: action.payload.id,
                phoneNumber: action.payload.phoneNumber, userScreenerId: action.payload.userScreenerId, signInError: undefined,
                hasAcceptedTerms: action.payload.hasAcceptedTerms, languagePreference: action.payload.preferredLanguage }
            return state
        case RESET_PASSWORD_FAILED:
            state = { ...state, jwt: undefined, signInError: action.payload.error }
            return state
        case PROVIDER_LOGIN_SUCCESS:
            state = { ...state,
                loginSource: "provider_web",
                provider: action.provider
            }
            return state
        case PROVIDER_LOGIN_FAILED:
            state = { ...state, providerSignInError: action.payload.error }
            return state
        case SET_USER_ID:
            state = { ...state, id: action.payload.userId }
            return state
        case CLEAR_USER_ID:
            state = { ...state, userScreenerId: undefined, userScreenerSlug: undefined, id: undefined, jwt: undefined, phoneNumber: undefined, nickName: undefined,
                createAccountError: undefined, signInError: undefined, isSentSuccessfully: undefined, isVerified: undefined, sendCodeError: undefined, codeVerifyError: undefined,
                practiceIdError: undefined, isTermsAccepted: undefined, isTermsAcceptedError: undefined, hasAcceptedTerms: undefined}
            return state

        case PATIENT_SCREENER_STARTED:
            state = { ...state, id: action.payload.id, userScreenerId: action.payload.userScreenerId, userScreenerSlug: action.payload.userScreenerSlug }
            return state
        case PATIENT_SCREENER_STARTING_FAILED:
            state = { ...state, patientScreenerError: action.payload.error }
            return state

        case PATIENT_SCREENER_VISITS_FAILED:
            state = { ...state, patientScreenerError: action.payload.error }
            return state
        case PATIENT_SCREENER_VISITS_SUCCESS:
            state = { ...state, visits: action.payload.visits }
            return state

        case ACCEPT_TERMS_SUCCESS:
            state = { ...state, isTermsAccepted: action.payload.isTermsAccepted }
            return state
        case ACCEPT_TERMS_FAILED:
            state = { ...state, isTermsAcceptedError: action.payload.error }
            return state
        case SET_LANGUAGE_PREFERENCE:
            state = {...state, languagePreference: action.payload.languagePreference}
            return state

        case SET_PRACTICE_SLUG:
            state = {...state, slug: action.payload.slug}
            return state

        case SET_SCREEN_NAME:
            state = {...state, screenName: action.payload.screenName}
            return state

        case CLEAR_USER_SCREENER_ID:
            state = {...state, userScreenerId: undefined, userScreenerSlug: undefined}
            return state

        default:
            return state;
    }
}

export default AccountReducer
