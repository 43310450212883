import {
    AllScreenerQuestion,
    IPatient,
    IPatientDetails, IPatientSummary,
    IScreenerQuestion,
    LanguageCodes,
    UserScreenerMultiSelect
} from "../../models"

export const FETCH_CURRENT_QUESTION = "FETCH_CURRENT_QUESTION"
export const FETCHED_CURRENT_QUESTION = "FETCHED_CURRENT_QUESTION"
export const FAILED_FETCHING_CURRENT_QUESTION = "FAILED_FETCHING_CURRENT_QUESTION"
export const FETCH_QUESTION_DETAILS = "FETCH_QUESTION_DETAILS"
export const FETCHED_QUESTION_DETAILS = "FETCHED_QUESTION_DETAILS"
export const FAILED_FETCHING_QUESTION_DETAILS = "FAILED_FETCHING_QUESTION_DETAILS"
export const SAVE_USER_SCREENER_ANSWER = " SAVE_USER_SCREENER_ANSWER"
export const SAVED_USER_SCREENER_ANSWER = "SAVED_USER_SCREENER_ANSWER"
export const FAILED_SAVING_USER_SCREENER_ANSWER = "FAILED_SAVING_USER_SCREENER_ANSWER"
export const SET_NEXT_QUESTION_LINK = "SET_NEXT_QUESTION_LINK"
export const CLEAR_SCREENER_QUESTIONS = "CLEAR_SCREENER_QUESTIONS"

export const FETCH_PATIENT="FETCH_PATIENT"
export const FETCH_PATIENT_SUCCESS="FETCH_PATIENT_SUCCESS"
export const FETCH_PATIENT_FAILED="FETCH_PATIENT_FAILED"
export const FETCH_PATIENTS="FETCH_PATIENTS"
export const FETCH_PATIENTS_SUCCESS="FETCH_PATIENTS_SUCCESS"
export const FETCH_PATIENTS_FAILED="FETCH_PATIENTS_FAILED"
export const SAVE_LANGUAGE_PREFERENCE = "SAVE_LANGUAGE_PREFERENCE"
export const SAVED_LANGUAGE_PREFERENCE = "SAVED_LANGUAGE_PREFERENCE"
export const SAVING_LANGUAGE_PREFERENCE_FAILED = "SAVING_LANGUAGE_PREFERENCE_FAILED"

export const getCurrentQuestion = (payload: {screenerId: string | undefined}) => {
    return {type: FETCH_CURRENT_QUESTION, payload}
}

export const fetchedCurrentQuestion = (payload: { tobeAnsweredQuestion: IScreenerQuestion, isScreenerCompleted: boolean, allScreenerQuestions: AllScreenerQuestion[]}) => {
    return {type: FETCHED_CURRENT_QUESTION, payload}
}

export const failedFetchingCurrentQuestion = (payload: { error: string }) => {
    return {type: FAILED_FETCHING_CURRENT_QUESTION, payload}
}

export const getQuestionDetails = (payload: {screenerId: string, questionId: string, preferredLanguage?: LanguageCodes}) => {
    return {type: FETCH_QUESTION_DETAILS, payload}
}

export const fetchedQuestionDetails = (payload: { currentQuestionDetails: IScreenerQuestion, allScreenerQuestions: AllScreenerQuestion[]}) => {
    return {type: FETCHED_QUESTION_DETAILS, payload}
}

export const failedFetchingQuestionDetails = (payload: { error: string }) => {
    return {type: FAILED_FETCHING_QUESTION_DETAILS, payload}
}

export const saveUserScreenerAnswer = (payload: {loginSource: string | undefined, screenerId: string, questionId: string, answerId: string, response?: UserScreenerMultiSelect[]}) => {
    return {type: SAVE_USER_SCREENER_ANSWER, payload}
}

export const savedUserScreenerAnswer = (payload: { nextQuestionLink: string, nextScreenerId: string|undefined, nextScreenerSlug: string|undefined, isScreenerPositive: boolean | undefined }) => {
    return {type: SAVED_USER_SCREENER_ANSWER, payload}
}

export const failedSavingUserScreenerAnswer = (payload: { error: string }) => {
    return {type: FAILED_SAVING_USER_SCREENER_ANSWER, payload}
}

export const setNextQuestionLink = () => {
    return {type: SET_NEXT_QUESTION_LINK}
}

export const fetchPatients = (basePath: string, pageNumber: number, recordsPerPage: number, searchText: string) => {
    return {type: FETCH_PATIENTS, payload: {basePath, pageNumber, recordsPerPage, searchText}}
}
export const fetchingPatientsFailed = (payload: {error: string}) => {
    return {type: FETCH_PATIENTS_FAILED, payload}
}
export const fetchedPatients = (payload: {patients: IPatientSummary[], recordsCount: number}) => {
    return {type: FETCH_PATIENTS_SUCCESS, payload}
}

export const fetchPatient = (patientId: string) => {
    return {type: FETCH_PATIENT, payload: {patientId}}
}
export const fetchingPatientFailed = (payload: {error: string}) => {
    return {type: FETCH_PATIENT_FAILED, payload}
}
export const fetchedPatient = (payload: {patient: IPatientDetails}) => {
    return {type: FETCH_PATIENT_SUCCESS, payload}
}
export const saveLanguagePreference = (payload: {preferredLanguage?: LanguageCodes}) => {
    return {type: SAVE_LANGUAGE_PREFERENCE, payload}
}
export const savedLanguagePreference = (payload: { preferredLanguage: LanguageCodes }) => {
    return {type: SAVED_LANGUAGE_PREFERENCE, payload}
}
export const savingLanguagePreferenceFailed = (payload: { error: string }) => {
    return {type: SAVING_LANGUAGE_PREFERENCE_FAILED, payload}
}

export const clearScreenerQuestions = () => {
    return {type: CLEAR_SCREENER_QUESTIONS}
}
